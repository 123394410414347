body {
  margin: 0;
}

.App {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

ul,
h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

.Social-Icon {
  width: 25px;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.5s;
}

.Social-Icon:hover {
  background-color: #bbdefb;
}

/* Hero */
/* Projects */

.Hero-Container,
.Projects-Container {
  background: linear-gradient(
      to right,
      rgba(245, 245, 245, 0.8),
      rgba(245, 245, 245, 0.8)
    ),
    url(https://d33wubrfki0l68.cloudfront.net/daac8858fd4a0ccea44f59dfd079c3c16c263f33/c157c/assets/svg/common-bg.svg);
  background-position: center;
}

.Projects-Container {
  padding: 24px 16px;
}
.projects-wrapper,
.abouts-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 45px;
}

/* About Me */
/* Contact */

.About-Container,
.Contact-Container {
  background-color: white;
}

/* Footer */

.Footer-Container {
  background-color: black;
}
